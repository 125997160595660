import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Container, TablePagination } from '@mui/material';
import '../styles/LeaderboardTradersData.css';

const LeaderboardTradersData = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = ['rank', 'owner', 'netBought', 'share', 'reward'];
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const fetchData = () => {
    axios.get(`${backendUrl}/api/data/leaderboard_traders`)
      .then(response => setData(response.data))
      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000); // Fetch data every 60 seconds
    return () => clearInterval(intervalId);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <div className="titleContainerLeaderboardTraders">
        <Typography variant="h4" gutterBottom className="titleLeaderboardTraders">
          👑 Daily Rewards : Traders
        </Typography>
      </div>
      <TableContainer component={Paper} className="tableContainerLeaderboardTraders">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((key, index) => (
                <TableCell key={index} className="tableHeaderLeaderboardTraders">
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={rowIndex} className="tableRowLeaderboardTraders">
                {columns.map((key, cellIndex) => (
                  <TableCell key={cellIndex} className="tableCellLeaderboardTraders">
                    {row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default LeaderboardTradersData;
