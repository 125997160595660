import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Container, TablePagination } from '@mui/material';
import axios from 'axios';
import './styles/HoldingsList.css'; // Import the CSS file

const HoldingsList = ({ userId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchHoldings = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/data/my_holdings_usdt?userId=${userId}`);
        const fetchedData = response.data;
        const formattedData = Object.keys(fetchedData)
          .map((key) => ({
            token: key,
            amount: fetchedData[key].Amount,
            value: fetchedData[key].PriceUsdt,
          }))
          .sort((a, b) => b.value - a.value); // Sort by value in descending order
        setData(formattedData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchHoldings();
  }, [userId, backendUrl]);

  const columns = ['Token', 'Amount', 'Value (USD)'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <Container>
      <div className="titleContainerLeaderboardHolders">
        <Typography variant="h4" gutterBottom className="titleLeaderboardHolders">
          📊 Holdings
        </Typography>
      </div>
      <TableContainer component={Paper} className="tableContainerLeaderboardHolders">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((key, index) => (
                <TableCell key={index} className="tableHeaderLeaderboardHolders">
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow key={rowIndex} className="tableRowLeaderboardHolders">
                <TableCell className="tableCellLeaderboardHolders">{row.token}</TableCell>
                <TableCell className="tableCellLeaderboardHolders">{row.amount}</TableCell>
                <TableCell className="tableCellLeaderboardHolders">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default HoldingsList;
