import React from 'react';
import { Container, Grid } from '@mui/material';
import TotalPortfolioChart from './TotalPortfolioChart'; // Assume you have this component
import PieChartHoldings from './PieChartHoldings'; // Assume you have this component
import HoldingsList from './HoldingsList'; // Assume you have this component

const Portfolio = ({ userId }) => {
    return (
        <Container>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={6} container direction="column" justifyContent="center">
                    <TotalPortfolioChart userId={userId} />
                </Grid>
                <Grid item xs={6}>
                    <PieChartHoldings userId={userId} />
                </Grid>
                <Grid item xs={12}>
                    <HoldingsList userId={userId} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Portfolio;
