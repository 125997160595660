import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { createChart } from 'lightweight-charts';
import axios from 'axios';

const PriceChart = ({ coin, timeframe, priceUnit, onPriceUpdate }) => {
    const priceChartContainerRef = useRef();
    const volumeChartContainerRef = useRef();
    const [priceChartInstance, setPriceChartInstance] = useState(null);
    const [volumeChartInstance, setVolumeChartInstance] = useState(null);
    const [candleSeries, setCandleSeries] = useState(null);
    const [volumeSeries, setVolumeSeries] = useState(null);
    const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const fetchData = () => {
        axios.get(`${backendUrl}/api/data/price_chart_user?coin=${coin}&timeframe=${timeframe}`)
            .then(response => {
                console.log('Data:', response.data);
                const data = response.data
                    .filter(row => row.Time)
                    .map(row => ({
                        time: new Date(row.Time).getTime() / 1000,
                        open: priceUnit === 'GALA' ? row.Open : row.PriceUsdt,
                        high: priceUnit === 'GALA' ? row.High : row.PriceUsdt,
                        low: priceUnit === 'GALA' ? row.Low : row.PriceUsdt,
                        close: priceUnit === 'GALA' ? row.Close : row.PriceUsdt,
                        volume: row.Volume // Adjust as per your volume data field
                    }));

                if (data.length > 0) {
                    onPriceUpdate(data[data.length - 1].close);
                    if (candleSeries && volumeSeries) {
                        candleSeries.setData(data);
                        volumeSeries.setData(data.map(d => ({
                            time: d.time,
                            value: d.volume,
                            color: d.close < d.open ? 'rgba(255, 82, 82, 0.5)' : 'rgba(20, 200, 80, 0.5)',
                        })));
                    }
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const updateChartColors = () => {
        const textColor = getComputedStyle(document.documentElement).getPropertyValue('--price-chart-text-color').trim();
        const gridColor = getComputedStyle(document.documentElement).getPropertyValue('--price-chart-grid-color').trim();

        if (priceChartInstance && volumeChartInstance) {
            const options = {
                layout: {
                    textColor: textColor,
                    background: { type: 'solid', color: 'transparent' },
                },
                grid: {
                    vertLines: { color: gridColor },
                    horzLines: { color: gridColor },
                },
                crosshair: {
                    vertLine: { color: '#6A5ACD' },
                    horzLine: { color: '#6A5ACD' },
                },
            };
            priceChartInstance.applyOptions(options);
            volumeChartInstance.applyOptions(options);
        }
    };

    useEffect(() => {
        const priceChartOptions = {
            layout: {
                textColor: getComputedStyle(document.documentElement).getPropertyValue('--price-chart-text-color').trim(),
                background: { type: 'solid', color: 'transparent' },
            },
            grid: {
                vertLines: {
                    color: getComputedStyle(document.documentElement).getPropertyValue('--price-chart-grid-color').trim(),
                    style: 1,
                    visible: true,
                },
                horzLines: {
                    color: getComputedStyle(document.documentElement).getPropertyValue('--price-chart-grid-color').trim(),
                    style: 1,
                    visible: true,
                },
            },
            crosshair: {
                vertLine: {
                    color: '#6A5ACD',
                    width: 1,
                    style: 0,
                    visible: true,
                    labelVisible: true,
                },
                horzLine: {
                    color: '#6A5ACD',
                    width: 1,
                    style: 0,
                    visible: true,
                    labelVisible: true,
                },
            },
            timeScale: {
                visible: false, // Hide the x-axis for the price chart
            },
            rightPriceScale: {
                scaleMargins: {
                    top: 0.1,
                    bottom: 0,
                },
                priceFormat: {
                    type: 'custom',
                    formatter: price => price.toFixed(2),
                },
            },
        };

        const volumeChartOptions = {
            layout: {
                textColor: getComputedStyle(document.documentElement).getPropertyValue('--price-chart-text-color').trim(),
                background: { type: 'solid', color: 'transparent' },
            },
            grid: {
                vertLines: {
                    color: getComputedStyle(document.documentElement).getPropertyValue('--price-chart-grid-color').trim(),
                    style: 1,
                    visible: true,
                },
                horzLines: {
                    color: getComputedStyle(document.documentElement).getPropertyValue('--price-chart-grid-color').trim(),
                    style: 1,
                    visible: true,
                },
            },
            priceScale: {
                scaleMargins: {
                    top: 0,
                    bottom: 0.8,
                },
                priceFormat: {
                    type: 'custom',
                    formatter: volume => `${(volume / 1000).toFixed(1)}K`,
                },
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time * 1000);
                    return date.toLocaleString(locale, {
                        month: 'short',
                        day: 'numeric',
                    });
                },
            },
        };

        const priceChart = createChart(priceChartContainerRef.current, priceChartOptions);
        const volumeChart = createChart(volumeChartContainerRef.current, volumeChartOptions);

        setPriceChartInstance(priceChart);
        setVolumeChartInstance(volumeChart);

        const candle = priceChart.addCandlestickSeries({
            upColor: '#4CAF50',
            downColor: '#F44336',
            borderDownColor: '#F44336',
            borderUpColor: '#4CAF50',
            wickDownColor: '#F44336',
            wickUpColor: '#4CAF50',
        });
        setCandleSeries(candle);

        const volume = volumeChart.addHistogramSeries({
            color: '#26a69a',
            priceLineVisible: false,
            priceFormat: {
                type: 'custom',
                formatter: volume => `${(volume / 1000).toFixed(0)}K`,
            },
            overlay: false,
        });
        setVolumeSeries(volume);

        // Synchronize the time scales
        const syncTimeScales = () => {
            const visibleLogicalRange = priceChart.timeScale().getVisibleLogicalRange();
            volumeChart.timeScale().setVisibleLogicalRange(visibleLogicalRange);
        };
        priceChart.timeScale().subscribeVisibleLogicalRangeChange(syncTimeScales);
        volumeChart.timeScale().subscribeVisibleLogicalRangeChange(syncTimeScales);

        const handleResize = () => {
            const containerWidth = priceChartContainerRef.current.clientWidth;
            const containerHeight = priceChartContainerRef.current.clientHeight + volumeChartContainerRef.current.clientHeight;
            priceChart.applyOptions({ width: containerWidth, height: priceChartContainerRef.current.clientHeight });
            volumeChart.applyOptions({ width: containerWidth, height: volumeChartContainerRef.current.clientHeight });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            priceChart.remove();
            volumeChart.remove();
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (candleSeries && volumeSeries) {
            fetchData();
        }
    }, [timeframe, candleSeries, volumeSeries, coin, priceUnit]);

    useEffect(() => {
        updateChartColors();

        const observer = new MutationObserver(() => {
            updateChartColors();
            setIsDarkMode(document.documentElement.classList.contains('dark'));
        });
        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

        return () => observer.disconnect();
    }, [priceChartInstance, volumeChartInstance]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div ref={priceChartContainerRef} style={{ flex: 4 }} />
            <div ref={volumeChartContainerRef} style={{ flex: 1 }} />
        </div>
    );
};

PriceChart.propTypes = {
    coin: PropTypes.string.isRequired,
    timeframe: PropTypes.string.isRequired,
    priceUnit: PropTypes.string.isRequired,
    onPriceUpdate: PropTypes.func.isRequired,
};

export default PriceChart;
