import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles(() => ({
    statsContainer: {
        marginTop: '20px',
    },
    statItem: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    statValue: {
        fontSize: '1.3rem ! important',
        fontWeight: 'normal',
    },
    statLabel: {
        fontSize: '0.8rem ! important',
        color: '#777',
    },
}));

const MyStats = ({ userId }) => {  // Accept userId as a prop
    const [stats, setStats] = useState({});
    const classes = useStyles();

    const fetchStatistics = async (userId) => {
        try {
            const response = await axios.get(`${backendUrl}/api/data/my_stats`, { params: { userId } });
            setStats(response.data);
        } catch (error) {
            console.error('Error fetching my stats:', error);
        }
    };

    useEffect(() => {
        fetchStatistics(userId);
    }, [userId]);  // Re-fetch data when userId changes

    const orderedStats = [
        'Total value',
        'Stat two',
        'Stat three',
        'Stat four',
    ];

    return (
        <Container className={classes.statsContainer}>
            <div className={classes.titleContainer}>
                {/* <Typography variant="h4" gutterBottom className={classes.title}>
                📈 My stats
                </Typography> */}
            </div>
            <Grid container spacing={3}>
                {orderedStats.map((label, index) => (
                    <Grid item xs={6} md={3} key={index} className={classes.statItem}>
                        <Typography className={classes.statLabel}>
                            {label}
                        </Typography>
                        <Typography className={classes.statValue}>
                            {stats[label]}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default MyStats;
