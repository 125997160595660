// utils/formatPrice.js
export const formatPrice = (price) => {
  if (price == null || isNaN(price)) {
      return 'N/A';
  }

  if (price < 0.01 && price > 0) {
      const exponent = Math.floor(Math.log10(price));
      const coefficient = (price / Math.pow(10, exponent)).toFixed(2);
      return `${coefficient}e${exponent}`;
  } else {
      return price.toFixed(2);
  }
};
