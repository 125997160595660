import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import darkLogo from '../assets/GC_logo_dark.svg'; // Adjust the path as needed
import lightLogo from '../assets/GC_logo_light.svg'; // Adjust the path as needed
import sunIcon from '../assets/sun.svg'; // Adjust the path as needed
import moonIcon from '../assets/moon.svg'; // Adjust the path as needed
import CoinSelectionMenu from './CoinSelectionMenu'; // Import the new component
import '../styles/Navbar.css'; // Import the new CSS file

const Navbar = ({ toggleTheme }) => {
  const [selectedCoin, setSelectedCoin] = useState('WEN'); // Set initial selected coin to "WEN"
  const [isDarkMode, setIsDarkMode] = useState(false); // State to manage the current theme
  const location = useLocation();

  useEffect(() => {
    // Check the initial theme based on the class of the document element
    const currentTheme = document.documentElement.classList.contains('dark');
    setIsDarkMode(currentTheme);

    // Observe changes to the class attribute on the document element
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const updatedTheme = document.documentElement.classList.contains('dark');
          setIsDarkMode(updatedTheme);
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => observer.disconnect();
  }, []);

  const handleCoinChange = (coin) => {
    setSelectedCoin(coin);
  };

  return (
    <div className="navbar-root">
      <AppBar position="static" className="navbar">
        <Toolbar className="navbar-toolbar">
          <Link to="/" className="navbar-title">
            <img
              src={isDarkMode ? lightLogo : darkLogo}
              alt="Logo"
              className={`navbar-logo navbar-logo-padding ${isDarkMode ? 'navbar-logo-glow-dark' : 'navbar-logo-glow-light'}`}
            />
          </Link>
          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            {location.pathname !== '/' && (
              <CoinSelectionMenu selectedCoin={selectedCoin} handleCoinChange={handleCoinChange} />
            )}
            <Button className="navbar-button" component={Link} to="/">Home</Button>
            <Button className="navbar-button" component={Link} to={`/charts/WEN`}>Charts</Button>
            <Button className="navbar-button" component={Link} to={`/user/${selectedCoin}`}>User</Button>
            <IconButton className="navbar-button" onClick={toggleTheme}>
              <img src={isDarkMode ? sunIcon : moonIcon} alt="theme icon" style={{ width: 24, height: 24 }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
