import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import UserBoard from './components/UserPage'; // Corrected import path

const App = () => {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      // document.body.classList.toggle('dark');
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      // document.body.classList.toggle('light');
      
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <Router>
      <div>
        <Navbar toggleTheme={toggleTheme} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/charts/:coin" component={Dashboard} />
          <Route path="/user/:coin" component={UserBoard} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
