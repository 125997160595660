import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import axios from 'axios';
import { Container, Typography, IconButton } from '@mui/material';

import darkIcon1 from '../assets/icons/dark/icon_galaswap.png';
import darkIcon2 from '../assets/icons/dark/icon_litepaper.png';
import darkIcon3 from '../assets/icons/dark/icon_twitter.png';

import lightIcon1 from '../assets/icons/light/icon_galaswap.png';
import lightIcon2 from '../assets/icons/light/icon_litepaper.png';
import lightIcon3 from '../assets/icons/light/icon_twitter.png';

import lightIconSwitch from '../assets/icons/light/icon_switch.png';
import darkIconSwitch from '../assets/icons/dark/icon_switch.png';

import coinsData from '../assets/listed_coins.json';
import logoMap from './logos';

import { formatPrice } from '../utils/formatPrice';
import '../styles/PriceChart.css';

const PriceChart = ({ coin }) => {
    const chartContainerRef = useRef();
    const priceLabelRef = useRef();
    const [chartInstance, setChartInstance] = useState(null);
    const [areaSeries, setAreaSeries] = useState(null);
    const [latestPrice, setLatestPrice] = useState(null);
    const [priceUnit, setPriceUnit] = useState('GALA');
    const [daysToDisplay, setDaysToDisplay] = useState(50);
    const [isDarkMode, setIsDarkMode] = useState(true);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const fetchData = () => {
        axios.get(`${backendUrl}/api/data/price_chart?coin=${coin}`)
            .then(response => {
                console.log('Data:', response.data);
                const data = response.data
                    .filter(row => row.Time && (priceUnit === 'GALA' ? row.Price : row.PriceUsdt))
                    .map(row => ({
                        time: new Date(row.Time).getTime() / 1000,
                        value: priceUnit === 'GALA' ? row.Price : row.PriceUsdt
                    }));

                if (data.length > 0) {
                    setLatestPrice(data[data.length - 1].value);
                    if (areaSeries) {
                        areaSeries.setData(data);
                        const now = new Date();
                        const startDate = new Date();
                        startDate.setDate(now.getDate() - daysToDisplay);
                        chartInstance.timeScale().setVisibleRange({
                            from: startDate.getTime() / 1000,
                            to: now.getTime() / 1000,
                        });
                    }
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const updateChartColors = () => {
        const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color').trim();
        const lineColor = getComputedStyle(document.documentElement).getPropertyValue('--line-color').trim();
        const topColor = getComputedStyle(document.documentElement).getPropertyValue('--top-color').trim();
        const bottomColor = getComputedStyle(document.documentElement).getPropertyValue('--bottom-color').trim();

        if (chartInstance) {
            chartInstance.applyOptions({
                layout: {
                    textColor: textColor,
                },
            });

            if (areaSeries) {
                areaSeries.applyOptions({
                    lineColor: lineColor,
                    topColor: topColor,
                    bottomColor: bottomColor,
                });
            }
        }
    };

    useEffect(() => {
        const chartOptions = {
            layout: {
                textColor: getComputedStyle(document.documentElement).getPropertyValue('--text-color').trim(),
                background: { type: 'solid', color: 'transparent' },
            },
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            },
            crosshair: {
                vertLine: {
                    color: '#6A5ACD',
                    width: 1,
                    style: 0,
                    visible: true,
                    labelVisible: true,
                },
                horzLine: {
                    color: '#6A5ACD',
                    width: 1,
                    style: 0,
                    visible: true,
                    labelVisible: true,
                },
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time * 1000);
                    return date.toLocaleString(locale, {
                        month: 'short',
                        day: 'numeric',
                    });
                },
            },
        };

        const chart = createChart(chartContainerRef.current, chartOptions);
        setChartInstance(chart);

        const series = chart.addAreaSeries({
            lineColor: getComputedStyle(document.documentElement).getPropertyValue('--line-color').trim(),
            topColor: getComputedStyle(document.documentElement).getPropertyValue('--top-color').trim(),
            bottomColor: getComputedStyle(document.documentElement).getPropertyValue('--bottom-color').trim(),
        });
        setAreaSeries(series);

        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            chart.remove();
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (areaSeries) {
            fetchData();
        }
    }, [areaSeries, coin, priceUnit, daysToDisplay]);

    useEffect(() => {
        updateChartColors();

        const observer = new MutationObserver(() => {
            updateChartColors();
            setIsDarkMode(document.documentElement.classList.contains('dark'));
        });
        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

        return () => observer.disconnect();
    }, [chartInstance, areaSeries]);

    const coinData = coinsData.coins[coin];
    const cleanCoin = coin.includes('$') ? coin.replace('$', '') : coin;
    const handlePriceUnitToggle = () => {
        setPriceUnit(priceUnit === 'GALA' ? 'USD' : 'GALA');
    };

    const icon1 = isDarkMode ? darkIcon1 : lightIcon1;
    const icon2 = isDarkMode ? darkIcon2 : lightIcon2;
    const icon3 = isDarkMode ? darkIcon3 : lightIcon3;
    const iconSwitch = isDarkMode ? darkIconSwitch : lightIconSwitch;

    return (
        <Container className="chart-container">
            <Typography variant="h4" gutterBottom className="chart-title">
                <img src={logoMap[cleanCoin]} alt={`${cleanCoin.toUpperCase()} Logo`} />
                {cleanCoin}
            </Typography>
            <div ref={chartContainerRef} className="chart-content" />
            {latestPrice !== null && (
                <div ref={priceLabelRef} className="price-label">
                    {formatPrice(latestPrice)} ${priceUnit}
                    <IconButton onClick={handlePriceUnitToggle}>
                        <img src={iconSwitch} alt="Switch Icon" />
                    </IconButton>
                </div>
            )}
            <div className="icon-container">
                <a href={coinData.galaswap} target="_blank" rel="noopener noreferrer">
                    <img src={icon1} alt="Icon 1" />
                </a>
                <a href={coinData.litepaper} target="_blank" rel="noopener noreferrer">
                    <img src={icon2} alt="Icon 2" />
                </a>
                <a href={coinData.twitter} target="_blank" rel="noopener noreferrer">
                    <img src={icon3} alt="Icon 3" />
                </a>
            </div>
        </Container>
    );
};

export default PriceChart;