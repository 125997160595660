import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import axios from 'axios';
import * as d3 from 'd3-scale-chromatic';
import './styles/TotalPortfolioChart.css'; // Import the CSS file

const TotalPortfolioChart = ({ userId }) => {
    const chartContainerRef = useRef();
    const [data, setData] = useState([]);
    const [isDarkMode, setIsDarkMode] = useState(false); // State to manage the current theme
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        // Fetch the data from the API
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/data/my_holdings_chart?userId=${userId}`);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [userId, backendUrl]);

    const updateChartColors = (chart) => {
        const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text-color').trim();
        const lineColor = getComputedStyle(document.documentElement).getPropertyValue('--line-color').trim();
        const topColor = getComputedStyle(document.documentElement).getPropertyValue('--top-color').trim();
        const bottomColor = getComputedStyle(document.documentElement).getPropertyValue('--bottom-color').trim();

        chart.applyOptions({
            layout: {
                textColor: textColor,
            },
        });

        chart.series.forEach((series) => {
            series.applyOptions({
                lineColor: lineColor,
                topColor: topColor,
                bottomColor: bottomColor,
            });
        });
    };

    useEffect(() => {
        if (data.length === 0) return;

        // Initialize the chart
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 300,
            layout: {
                textColor: getComputedStyle(document.documentElement).getPropertyValue('--text-color').trim(),
                background: { type: 'solid', color: 'transparent' },
            },
            grid: {
                vertLines: {
                    visible: false, // Hide vertical grid lines
                },
                horzLines: {
                    visible: false, // Hide horizontal grid lines
                },
            },
            crosshair: {
                vertLine: {
                    color: '#6A5ACD',
                    width: 1,
                    style: 0,
                    visible: true,
                    labelVisible: true,
                },
                horzLine: {
                    color: '#6A5ACD',
                    width: 1,
                    style: 0,
                    visible: true,
                    labelVisible: true,
                },
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time * 1000);
                    return date.toLocaleString(locale, {
                        month: 'short',
                        day: 'numeric',
                    });
                },
            },
        });

        // Transform data into series format for each holding
        const seriesData = {};
        data.forEach(entry => {
            Object.keys(entry).forEach(key => {
                if (key !== 'Time') {
                    if (!seriesData[key]) {
                        seriesData[key] = [];
                    }
                    seriesData[key].push({ time: new Date(entry.Time).getTime() / 1000, value: entry[key] });
                }
            });
        });

        // Function to generate color palette
        const generateColorPalette = (numColors) => {
            const colors = [];
            for (let i = 0; i < numColors; i++) {
                colors.push(d3.schemeCategory10[i % 10]);
            }
            return colors;
        };

        // Get a list of colors based on the number of series
        const colors = generateColorPalette(Object.keys(seriesData).length);

        // Add series to chart with dynamic colors
        const series = [];
        Object.keys(seriesData).forEach((key, index) => {
            const seriesItem = chart.addAreaSeries({
                title: key,
                topColor: colors[index] + '80', // Adding 80 for alpha channel
                bottomColor: colors[index] + '20', // Adding 20 for alpha channel
                lineColor: colors[index],
            });
            seriesItem.setData(seriesData[key]);
            series.push(seriesItem);
        });

        chart.series = series;

        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
        };

        window.addEventListener('resize', handleResize);

        // Observe changes to the class attribute on the document element
        const observer = new MutationObserver(() => {
            updateChartColors(chart);
            setIsDarkMode(document.documentElement.classList.contains('dark'));
        });

        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });

        // Cleanup function
        return () => {
            chart.remove();
            window.removeEventListener('resize', handleResize);
            observer.disconnect();
        };
    }, [data]);

    return (
        <div className="chart-container" ref={chartContainerRef}></div>
    );
};

export default TotalPortfolioChart;
